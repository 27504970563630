/*
 * @Date: 2023-11-09 15:56:57
 */
// @ts-nocheck
const pixelId = "311992614956486";

(function (f, b, e, v, n, t, s) {
  if (f.fbq) return;
  n = f.fbq = function () {
    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
  };
  if (!f._fbq) f._fbq = n;
  n.push = n;
  n.loaded = !0;
  n.version = "2.0";
  n.queue = [];
  t = b.createElement(e);
  t.async = !0;
  t.src = v;
  s = b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t, s);
})(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");


export const fbqinit = () => {
  fbq("init", pixelId);
}


// export default function (eventName, eventParams) {
//   if (typeof fbq !== "undefined") {
//     fbq("track","AddToCart", {currency:eventName, value:eventParams});
//   }
// };

export const fbqClick = (clickType,eventName, eventParams) => {
  fbq("track",clickType, {currency:eventName, value:eventParams});
}
export const fbqClickSubscribe = () => {
  fbq('track', 'Subscribe', {value: '0.00', currency: 'USD', predicted_ltv: '0.00'});
}
export const fbqClickStartTrial = () => {
  fbq('track', 'StartTrial', {value: '0.00', currency: 'USD', predicted_ltv: '0.00'});
}
export const fbqClickLead = () => {
  fbq('track', 'Lead');
}
export const fbqClickAddToCart = () => {
  // fbq("track",clickType);
  fbq('track', 'AddToCart');
}