/*
 * @Date: 2023-11-08 16:40:58
 */
import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import directives from "./directives/index";

import "animate.css";

import "./plugins/flexble";
import "./assets/styles/main.less";
import "./assets/styles/index.less";
import "vant/lib/index.css";
import Haa from "./utils/index";
import { fbqinit } from "./utils/facebook-pixel";
fbqinit();
const app = createApp(App);
app.use(store);
app.use(directives);
app.use(router);
const cfg = {
  queryMap: {
    // query的key
    channel: "channel",
    subchannel: "subchannel",
    key: "key",
    source: ""
  },

  router: router, // router实例
  home: "", // 路由首页的name，对于首页有多个子页面的，可以传数组['Home','Analysis']
  app: app, // 应用实例
  deepStart: process.env.VUE_APP_START == "guide" ? 1 : 2
};
Haa.init(cfg);

app.mount("#app");
